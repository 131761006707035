<template>
  <div class="top-issues mb-4">
    <div class="section-header font-weight-bold">
      <span>Commonly Reported Issues</span>
    </div>
    <b-modal
      ref="provide-details"
      hide-footer
      hide-header
      no-close-on-backdrop
      centered
      title="BootstrapVue"
      ><h1>Vote Counted</h1>
      <p>
        Thank you! Would you like to add additional details such as platform you
        are playing on or where in the game you experienced the bug?
      </p>
      <b-row
        ><b-col>
          <b-button variant="danger" block @click="noDetails">No</b-button>
        </b-col>
        <b-col>
          <b-button variant="primary" block @click="addDetails"
            >Yes, add details</b-button
          ></b-col
        ></b-row
      >
    </b-modal>
    <b-row>
      <div class="col-6 mb-n4">
        <cv-text-input
          label="Search"
          @input="onInput"
          :value="searchText"
        ></cv-text-input></div
    ></b-row>

    <cv-data-table
      :auto-width="false"
      :pagination="{
        numberOfItems: itemsLength,
        pageSizes: [10, 15, 20, 25, 50],
      }"
      @pagination="actionOnPagination"
      ref="table"
    >
      <template slot="data">
        <cv-data-table-row
          v-for="issue in filteredData"
          :key="`${issue.id}`"
          :value="`${issue.id}`"
        >
          <cv-data-table-cell style="width: 130px">
            <div class="row">
              <div class="issue--fake-border"></div>
              <b-badge class="align-self-baseline mr-3"
                ><span class="count issue--count">{{ issue.report_count }}</span
                ><br />Reports</b-badge
              >
            </div>
          </cv-data-table-cell>
          <cv-data-table-cell>
            <h2 class="issue--title">{{ issue.title }}</h2>
          </cv-data-table-cell>
          <cv-data-table-cell style="width: 120px">
            <div v-if="issue.fixed" class="issue--title">
              <cv-tag kind="green" label="Next Patch"> </cv-tag>
            </div>
            <div v-if="issue.in_progress" class="issue--title">
              <cv-tag kind="cyan" label="In Progress"> </cv-tag>
            </div>
          </cv-data-table-cell>
          <cv-data-table-cell style="width: 120px">
            {{ issue.type }}
          </cv-data-table-cell>
          <cv-data-table-cell style="width: 30px">
            <b-button
              variant="success"
              class="ml-3 upvote-button"
              @click="upvoteIssue(issue.id, issue.title)"
              :disabled="upvotedIssues.includes(issue.id)"
              ><span v-if="!upvotedIssues.includes(issue.id)">+1</span
              ><span v-else>✔️</span></b-button
            >
          </cv-data-table-cell>
        </cv-data-table-row>
      </template>
    </cv-data-table>
    <!-- <div class="d-flex flex-column flex-wrap mb-2">
      <div
        v-for="issue in issues.slice(issueIndex, issueIndex + issuePageSize)"
        :key="issue.id"
        class="issue-container"
      >
        <div
          class="d-flex flex-row justify-content-between align-items-center issue p-2 m-1"
        >
          <b-badge class="align-self-baseline mr-3"
            ><span class="count">{{ issue.report_count }}</span
            ><br />Reports</b-badge
          >
          <span
            v-b-tooltip.hover
            :title="issue.fix_notes"
            class="title w-100"
            >{{ issue.title }}</span
          >
          <b-button
            variant="success"
            class="ml-3 upvote-button"
            @click="upvoteIssue(issue.id, issue.title)"
            :disabled="upvotedIssues.includes(issue.id)"
            ><span v-if="!upvotedIssues.includes(issue.id)">+1</span
            ><span v-else>✔️</span></b-button
          >
          <!-- </router-link> -->
    <!-- </div>
      </div>
    </div> -->
  </div>
</template>

<script>
  import axios from "axios";
  import { mapGetters } from "vuex";

  export default {
    name: "TopIssues",
    data: () => ({
      columns: ["Reports", "Title", "Status", "Type", "+1"],
      issues: [],
      selectedIssue: "",
      selectedTitle: "",
      searchText: "",
      pageStart: 0,
      pageLength: 50,
      itemsLength: 0,
    }),
    computed: {
      ...mapGetters(["upvotedIssues"]),
      filteredData() {
        let filteredData;
        if (this.searchText) {
          filteredData = this.issues.filter((issue) => {
            return (
              issue.title
                .toLowerCase()
                .indexOf(this.searchText.toLowerCase()) !== -1
            );
          });
        } else {
          filteredData = this.issues;
        }
        this.itemsLength = filteredData.length;
        if (this.pageStart) {
          return filteredData.slice(
            this.pageStart - 1,
            this.pageStart - 1 + this.pageLength
          );
        } else {
          return filteredData;
        }
      },
    },
    async created() {
      const result = await axios({
        method: "get",
        url: `${process.env.VUE_APP_API_ROOT}/issues/all`,
      });
      this.issues = result.data;
    },
    methods: {
      actionOnPagination(ev) {
        this.pageLength = ev.length;
        this.pageStart = ev.start;
        this.page = ev.page;
      },
      onInput(val) {
        this.searchText = val;
      },
      async upvoteIssue(issue_id, issue_title) {
        const result = await axios({
          method: "post",
          url: `${process.env.VUE_APP_API_ROOT}/issues/${issue_id}/upvote`,
        });

        if (result.status == 200) {
          this.$store.commit("storeUpvotedIssueID", issue_id);
        }
        this.selectedIssue = issue_id;
        this.selectedTitle = issue_title;
        this.$refs["provide-details"].show();
      },
      noDetails() {
        this.$refs["provide-details"].hide();
        this.selectedIssue = "";
        this.selectedTitle = "";
      },
      addDetails() {
        this.$router.push({
          name: "Report Bug",
          params: {
            issue_id: this.selectedIssue,
            title: this.selectedTitle,
            message:
              "Thanks for telling us that you are also running into this issue. Any extra information you can provide would be appreciated.",
          },
        });
        this.selectedIssue = "";
        this.selectedTitle = "";
      },
    },
  };
</script>

<style lang="scss">
  @import "../assets/css/variables.scss";
  .issue--fake-border {
    background-color: #738d45;
    min-width: 0.5em;
    width: 0.5em;
    margin-left: 15px;
  }
  .badge {
    min-width: 80px;
  }
  .issue--count {
    font-family: roboto-condensed, Roboto Condensed, sans-serif;
    font-weight: 800;
    font-size: 1.7em;
    min-width: 100px;
  }
  .issue--title {
    font-family: roboto-condensed, Roboto Condensed, sans-serif;
    font-weight: 800;
    font-size: 1.7em;
  }
  .issue-container {
    .issue {
      border-left: solid 0.75rem $success;
    }
  }

  .upvote-button {
    font-weight: bold;
    font-size: 1.5rem;
    min-width: 50px;
    margin: 10px;
  }

  @media (max-width: 768px) {
    .issue-container {
      width: 100%;
    }
  }

  .disabled {
    pointer-events: none;
    &.upvote-button {
      background-color: #353330;
    }
  }
  .tooltip .tooltip-inner {
    max-width: 500px !important;
  }
  .modal-body {
    background-color: #353330;
  }
</style>
