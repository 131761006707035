<template>
  <div class="action-buttons mb-4">
    <b-button variant="danger" class="action-button mr-2" v-bind:to="cleanRouteString(route)">{{ buttonText }}</b-button>
    <!-- <b-button variant="primary">Send Feedback</b-button> -->
  </div>
</template>

<script>
export default {
  name: 'ActionButtons',
  props: ['buttonText', 'route'],
  methods: {
    // Clean route string to prevent user error passing the prop.
    cleanRouteString: function (string) {
      if (!string) {
        return '/';
      }
      
      let stringCopy = string;
      // Add prefix slash if not added.
      if (!stringCopy.startsWith('/')) {
        stringCopy = `/${stringCopy}`;
      }
      // Remove any accidental spaces.
      const routeString = stringCopy.split(' ').join('-');
      return routeString.toLowerCase();
    }
  },
}
</script>

<style lang="scss">
.action-button {
  font-size: 30px;
  padding: 0.25rem 1.5rem;
  font-weight: bold;
}
</style>