<template>
  <div class="home">
    <b-alert show variant="success" v-if="this.$route.params.message">
      {{ this.$route.params.message }}
    </b-alert>
    <div class="button-group--row">
      <ActionButtons buttonText="REPORT A BUG" route="/report-bug" class="my-3" />
      <ActionButtons buttonText="STATUS" route="/status" class="my-3" />
    </div>    
    <div class="row">
      <div class="col">
        <TopIssues class="mb-5" />
      </div>
    </div>
  </div>
</template>

<script>
  // @ is an alias to /src
  import ActionButtons from "@/components/ActionButtons.vue";
  import TopIssues from "@/components/TopIssues.vue";

  export default {
    name: "Bugs",
    components: {
      ActionButtons,
      TopIssues,
    },
    methods: {
      getPlatformColor: function (platform) {
        if (platform.includes("Xbox")) {
          return "success";
        } else if (platform.includes("PS4")) {
          return "primary";
        }
      },
      getIssueColor: function (issue) {
        switch (issue) {
          case "networking":
            return "light";
          case "balance":
            return "secondary";
          case "performance":
            return "dark";
          case "crash":
            return "danger";
          case "gameplay":
            return "warning";
          case "user interface":
            return "info";
        }
      },
    },
  };
</script>

<style lang="scss">
  .button-group--row {
    display: flex;
    
    .action-buttons {
      margin-right: 1rem;
    }
  }
</style>